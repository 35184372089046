import React, { useState } from "react";
import Section from "../../components/Section/Section";
import useFormFields from "../../utils/use-form-fields-hook";
import encode from "../../utils/encode";
import "./ContactForm.scss";

const ContactForm = ({ formDescription, getInTouch, successMessage, errorMessage }) => {
  const [contactData, handleContactDataChange] = useFormFields({
    name: "",
    email: "",
    message: "",
  });

  const [showSuccess, changeShowSuccess] = useState(false);
  const [error, changeError] = useState(false);

  const submitMail = async (event) => {
    event.preventDefault();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...contactData }),
    })
      .then(() => changeShowSuccess(true))
      .catch((error) => changeError(true));
  };

  return (
    <Section className="ContactForm">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="GetInTouch">
              <h3>Get in touch</h3>
              <h5>Email</h5>
              {getInTouch.emails?.map(({ email }, i) => {
                return (
                  <a href={`mailto:${email}`} key={i} className="Body--small">
                    {email}
                  </a>
                );
              })}
              <h5>Phone</h5>
              <a href={`tel:${getInTouch.phone}`} className="Body--small">
                {getInTouch.phone}
              </a>
              <h5>Address</h5>
              <p className="Body--small" dangerouslySetInnerHTML={{ __html: getInTouch.address }} />
            </div>
          </div>
          <div className="col-lg-8 order-1 order-sm-2">
            <div className="FormSection">
              <div
                className="ContactFormDescription Body--large"
                dangerouslySetInnerHTML={{ __html: formDescription }}
              />
              <div className="ContactFormSection">
                {!showSuccess ? (
                  <form
                    method="post"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    name="contact"
                    onSubmit={submitMail}
                  >
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Your Name"
                      className="Body--small"
                      value={contactData.name}
                      onChange={handleContactDataChange}
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      className="Body--small"
                      value={contactData.email}
                      onChange={handleContactDataChange}
                      required
                    />
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Type your message here…"
                      className="Body--small"
                      value={contactData.message}
                      onChange={handleContactDataChange}
                      required
                    />
                    <button className="Button light" type="submit">
                      Send Your Message
                    </button>
                  </form>
                ) : (
                  <div className="SuccessMessage">
                    <p className="Body--large Text--center">
                      {error ? errorMessage : successMessage}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ContactForm;
